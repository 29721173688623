<template>
  <div class="job-container">
    <div class="g-section">
      <div class="g-wrap mt-20 p-20">
        <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="地区"></el-cascader>
        <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="行业"></el-cascader>
        <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="岗位"></el-cascader>
        <el-input placeholder="请输入关键字搜索" v-model="searchKeyword" class="mr-10 input-with-select">
        </el-input>
        <el-button type="primary" icon="el-icon-search">搜索</el-button>
      </div>
    </div>
    <div class="g-section">
      <div class="g-wrap flex-column pr-20 pl-20">
        <div :class="`${isExpand?'condition-section':'condition-section-hide'}`">
          <div class="job-condition">
            <span class="job-condition-title">月薪范围:</span>
            <div class="job-condition-wrap">
              <span class="job-condition-item checked">不限</span>
              <span class="job-condition-item">3K以下</span>
              <span class="job-condition-item">3-5K</span>
              <span class="job-condition-item">5-10K</span>
              <span class="job-condition-item">10-20K</span>
              <span class="job-condition-item">20K以上</span>
            </div>
          </div>
          <div class="job-condition">
            <span class="job-condition-title ">工作经验:</span>
            <div class="job-condition-wrap ">
              <span class="job-condition-item checked">不限</span>
              <span class="job-condition-item">0-1年</span>
              <span class="job-condition-item">1-3年</span>
              <span class="job-condition-item">3-5K</span>
              <span class="job-condition-item">5-10K</span>
              <span class="job-condition-item">10年以上</span>
            </div>
          </div>
          <div class="job-condition">
            <span class="job-condition-title">学历要求:</span>
            <div class="job-condition-wrap">
              <span class="job-condition-item checked">不限</span>
              <span class="job-condition-item">大专以下</span>
              <span class="job-condition-item">大专</span>
              <span class="job-condition-item">本科</span>
              <span class="job-condition-item">硕士</span>
              <span class="job-condition-item">博士</span>
            </div>
          </div>
          <div class="job-condition">
            <span class="job-condition-title">职位类型:</span>
            <div class="job-condition-wrap ">
              <span class="job-condition-item checked">不限</span>
              <span class="job-condition-item">全职</span>
              <span class="job-condition-item">兼职</span>
              <span class="job-condition-item">实习</span>
            </div>
          </div>
          <!--福利标签 -->
        </div>
        <div class="job-condition-btn mb-10">
          <el-button type="text" :icon="`${isExpand?'el-icon-arrow-up':'el-icon-arrow-down'}`" @click="isExpand=!isExpand">{{isExpand?'收起':'更多'}}</el-button>
        </div>
      </div>
    </div>
    <div class="g-section mt-20 mb-20">
      <div class="g-wrap flex-column p-20">
        <div class="sel-condition">
          <div>
            <span class="sel-condition-title">已选条件:</span>
            <div class="sel-condition-wrap ">
            </div>
          </div>
          <span>共找到<span class="job-title">888+</span>个职位</span>
        </div>
        <div>
          <el-table :data="listJob" stripe style="width: 100%">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="Name" label="职位名称">
            </el-table-column>
            <el-table-column prop="Enterprise" label="公司名称">
            </el-table-column>
            <el-table-column prop="Area" label="工作地点" width="120">
            </el-table-column>
            <el-table-column prop="Education" label="学历" width="80">
            </el-table-column>
            <el-table-column prop="Salary" label="月薪" width="80">
            </el-table-column>
            <el-table-column prop="Experience" label="经验" width="80">
            </el-table-column>
            <el-table-column prop="UpdatedTime" label="更新日期" width="100">
            </el-table-column>
            <el-table-column prop="Experience" label="收藏" width="80">
              <template slot-scope="scope">
                <i :class="`icon-collect ${scope.row.IsAdd?'el-icon-star-on':'el-icon-star-off'}`"></i>
              </template>
            </el-table-column>
          </el-table>
          <div class="table-bottom">
            <el-button type="primary" size="small">申请选中职位</el-button>
            <el-pagination background layout="prev, pager, next" :total="1000">
            </el-pagination>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchZone: '1',
      searchKeyword: '',
      searchArea: [],
      optionsArea: [{
        value: 'gd',
        label: '广东',
        children: [{
          value: 'sz',
          label: '深圳',
          children: [{
            value: 'lh',
            label: '龙华'
          }, {
            value: 'ns',
            label: '南山'
          }, {
            value: 'ft',
            label: '福田'
          }, {
            value: 'lg',
            label: '龙岗'
          }]
        }, {
          value: 'gz',
          label: '广州',
          children: [{
            value: 'by',
            label: '白云'
          }, {
            value: 'th',
            label: '天河'
          }]
        }]
      }, {
        value: 'hn',
        label: '湖南',
        children: [{
          value: 'ch',
          label: '长沙',
          children: [
            {
              value: 'qb',
              label: '全部'
            }
          ]
        }]
      }],
      optionsIndustry: [],
      listEnterprise: [
        { Id: '1', Logo: '', Name: '企业1' },
        { Id: '2', Logo: '', Name: '企业2' },
        { Id: '3', Logo: '', Name: '企业3' },
        { Id: '4', Logo: '', Name: '企业4' },
        { Id: '5', Logo: '', Name: '企业5' },
        { Id: '6', Logo: '', Name: '企业6' },
        { Id: '7', Logo: '', Name: '企业7' },
        { Id: '8', Logo: '', Name: '企业8' }
      ],
      listJob: [
        { Id: '1', Logo: '', Name: '前端开发', Enterprise: '智邻科技', Area: '深圳', Education: '本科', Salary: '6-8K', Experience: '3-5年', UpdatedTime: '2020-12-30', IsAdd: true },
        { Id: '2', Logo: '', Name: '销售助理', Enterprise: '智邻科技', Area: '深圳', Education: '本科', Salary: '6-8K', Experience: '3-5年', UpdatedTime: '2020-12-30' },
        { Id: '3', Logo: '', Name: '市场推广', Enterprise: '智邻科技', Area: '深圳', Education: '本科', Salary: '6-8K', Experience: '1-3年', UpdatedTime: '2020-12-30' },
        { Id: '4', Logo: '', Name: '架构师', Enterprise: '智邻科技', Area: '深圳', Education: '本科', Salary: '25K以上', Experience: '5-10年', UpdatedTime: '2020-12-30', IsAdd: true },
        { Id: '5', Logo: '', Name: '系统分析师', Enterprise: '智邻科技', Area: '深圳', Education: '本科', Salary: '15K', Experience: '3-5年', UpdatedTime: '2020-12-30' },
        { Id: '6', Logo: '', Name: '岗位1', Enterprise: '智邻科技', Area: '深圳', Education: '本科', Salary: '6-8K', Experience: '3-5年', UpdatedTime: '2020-12-30' },
        { Id: '7', Logo: '', Name: '岗位3', Enterprise: '智邻科技', Area: '深圳', Education: '本科', Salary: '6-8K', Experience: '3-5年', UpdatedTime: '2020-12-30', IsAdd: true }
      ],
      isExpand: false
    }
  }
}
</script>

<style lang="scss" scoped>
.job-container {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  flex: 1;
}
.job-banner {
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #d3dfe8;
  justify-content: center;
  align-items: center;
}
.g-section {
  background-color: #f6f6f8;
}
.g-wrap {
  background-color: #ffffff;
}
.condition-section {
  flex-direction: column;
  padding-top: 0px;
}
.job-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}
.job-item {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 360px;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
}
.condition-section-hide{
  display: none;
}
.job-condition {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .job-condition-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
  .job-condition-wrap {
    display: flex;
    .job-condition-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 3px;
      margin-right: 10px;
      &:hover {
        background-color: #409eff;
        color: #ffffff;
      }
    }
    .checked {
      background-color: #409eff;
      color: #ffffff;
    }
  }
}
.job-condition-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sel-condition {
  border: 1px solid #b3d8ff;
  background-color: #ecf5ff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  .sel-condition-title {
    color: #409eff;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
}
.job-title {
  color: #409eff;
  font-size: 18px;
}
.table-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-collect {
  color: #409eff;
  font-size: 18px;
  cursor: pointer;
}
</style>